import React, {useRef, useState} from 'react';
import styled from 'styled-components';

import EmailEditor, {EditorRef, EmailEditorProps} from 'react-email-editor';
import {projectId} from '../configs';
import {copyToClipBoard} from '../utils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`;

const Bar = styled.div`
  flex: 1;
  background-color: #ffffff;
  color: #000;
  padding: 10px;
  display: flex;
  max-height: 40px;
  box-shadow: 0 2px 8px 0 rgba(119, 126, 144, .36), 0 0 24px 0 rgba(119, 126, 144, .08), 0 0 0 0.25px #e6e8ec;
  position: relative;
  z-index: 10;

  h1 {
    flex: 1;
    font-size: 16px;
    text-align: left;
  }

  button {
    flex: 1;
    padding: 10px;
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
    background-color: #1d2592;
    color: #fff;
    border: 0px;
    border-radius: 4px;
    max-width: 150px;
    cursor: pointer;
  }
`;

const Example = () => {
    const emailEditorRef = useRef<EditorRef | null>(null);
    const [preview, setPreview] = useState(false);

    const saveDesign = () => {
        const unlayer = emailEditorRef.current?.editor;

        unlayer?.saveDesign((design) => {
            console.log('saveDesign', design);
            alert('Design JSON has been logged in your developer console.');
        });
    };

    const exportHtml = () => {
        const unlayer = emailEditorRef.current?.editor;

        unlayer?.exportHtml((data) => {
            const {design, html} = data;
            console.log('exportHtml', html);
            alert('Output HTML has been logged in your developer console.');
        });
    };

    const copyHtml = () => {
        const unlayer = emailEditorRef.current?.editor;

        unlayer?.exportHtml(async (data) => {
            const {design, html} = data;
            await copyToClipBoard(html);
        });
    }

    const togglePreview = () => {
        const unlayer = emailEditorRef.current?.editor;

        if (preview) {
            unlayer?.hidePreview();
            setPreview(false);
        } else {
            unlayer?.showPreview('desktop');
            setPreview(true);
        }
    };

    const onDesignLoad = (data) => {
        console.log('onDesignLoad', data);
    };

    const onLoad: EmailEditorProps['onLoad'] = (unlayer) => {
        console.log('onLoad', unlayer);
        unlayer.addEventListener('design:loaded', onDesignLoad);
        // @ts-ignore
        // unlayer.loadDesign(sample);
    };

    const onReady: EmailEditorProps['onReady'] = (unlayer) => {
        console.log('onReady', unlayer);
    };

    return (
        <Container>
            <Bar>
                <h1>RovaHub Technologies</h1>
                <button onClick={togglePreview}>
                    {preview ? 'Hide' : 'Show'} Preview
                </button>
                 <button onClick={saveDesign}>Save Design</button>
                {/* <button onClick={exportHtml}>Export HTML</button> */}
                <button onClick={copyHtml}>Copy HTML</button>
            </Bar>
            <React.StrictMode>
                <EmailEditor projectId={projectId} ref={emailEditorRef} onReady={onReady} onLoad={onLoad}/>
            </React.StrictMode>
        </Container>
    );
};

export default Example;

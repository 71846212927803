import React from 'react';
import { Routes, Route } from 'react-router-dom';

import DesignList from './DesignList';
import DesignEdit from './DesignEdit';
import Editor from '../editor';

const Dashboard = () => {
  return (
    <Routes>
      <Route path="/" element={<DesignList />} />
      <Route path={`/design/new`} element={<Editor />} />
      <Route path={`/design/:designId/edit`} element={<DesignEdit />} />
    </Routes>
  );
};

export default Dashboard;

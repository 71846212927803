import toast from 'react-hot-toast';

export const copyToClipBoard = async (textToCopy: string) => {
    try {
        await navigator.clipboard.writeText(textToCopy);
        toast.success("Copied to clipboard !")
    } catch (err) {
        toast.error('Error copying text to clipboard !');
    }
}

import React, {useRef, useState} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import EmailEditor, {EditorRef} from 'react-email-editor';
import {projectId} from '../configs';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`;

const Bar = styled.div`
  flex: 1;
  background-color: #ffffff;
  color: #000;
  padding: 10px;
  display: flex;
  max-height: 40px;
  box-shadow: 0 2px 8px 0 rgba(119, 126, 144, .36), 0 0 24px 0 rgba(119, 126, 144, .08), 0 0 0 0.25px #e6e8ec;
  position: relative;
  z-index: 10;

  h1 {
    flex: 1;
    font-size: 16px;
    text-align: left;
  }

  button {
    flex: 1;
    padding: 10px;
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
    background-color: #1d2592;
    color: #fff;
    border: 0px;
    border-radius: 4px;
    max-width: 150px;
    cursor: pointer;
  }

  a {
    flex: 1;
    padding: 10px;
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    border: 0px;
    border-radius: 4px;
    cursor: pointer;
    text-align: right;
    text-decoration: none;
    line-height: 160%;
  }
`;

const DesignEdit = () => {
    const emailEditorRef = useRef<EditorRef | null>(null);
    const [preview, setPreview] = useState(false);

    const saveDesign = () => {
        const unlayer = emailEditorRef.current?.editor;

        unlayer?.saveDesign((design) => {
            console.log('saveDesign', design);
            alert('Design JSON has been logged in your developer console.');
        });
    };

    const exportHtml = () => {
        const unlayer = emailEditorRef.current?.editor;

        unlayer?.exportHtml((data) => {
            const {html} = data;
            console.log('exportHtml', html);
            alert('Output HTML has been logged in your developer console.');
        });
    };

    const togglePreview = () => {
        const unlayer = emailEditorRef.current?.editor;

        if (preview) {
            unlayer?.hidePreview();
            setPreview(false);
        } else {
            unlayer?.showPreview('desktop');
            setPreview(true);
        }
    };

    return (
        <Container>
            <Bar>
                <h1>RovaHub Technologies</h1>
                <Link to={`/dashboard`}>Dashboard</Link>
                <button onClick={togglePreview}>{preview ? 'Hide' : 'Show'} Preview</button>
                <button onClick={saveDesign}>Save Design</button>
                <button onClick={exportHtml}>Export HTML</button>
            </Bar>

            <EmailEditor projectId={projectId} ref={emailEditorRef}/>
        </Container>
    );
};

export default DesignEdit;
